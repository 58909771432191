<template>
<div > 
  
  <router-view :key="$route.fullPath" />
 
</div>  
  
</template>


<script>
// @ is an alias to /src



export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
/* a.router-link-active{
  background: red;
} */
#app {
  font-family: Gilroy , sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

.kardaan-hr{
  background-color : #ffca27 !important;
  color: #ffca27;
  height: 4px !important;
  opacity: 1;
}

 .swal2-styled.swal2-confirm {
    background: #75BF7A !important;
    border-color: #75BF7A !important;
 }
</style>
