import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
import router from './router'

// Bootstrap 5
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/js/bootstrap.js"
// Bootstrap icons
import 'bootstrap-icons/font/bootstrap-icons.css'
import CKEditor from '@ckeditor/ckeditor5-vue';

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

//app.config.globalProperties.$my_domain_images = 'https://www.kardaan.app/blog-img/'
app.config.globalProperties.$my_domain_name = 'https://www.tererides.com/terewebapp/'
app.use(router)
app.use(CKEditor)
app.use(VueLoading)
app.mount('#app')



