import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: 'home' */'@/components/home.vue'),
  },

  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: 'privacy-policy' */'@/components/privacy-policy.vue')
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: () => import(/* webpackChunkName: 'terms-conditions' */'@/components/terms-conditions.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: 'contact' */'@/components/contact.vue')
  },

  {
    path: '/how-to-delete-account',
    name: 'delete-account',
    component: () => import(/* webpackChunkName: 'contact' */'@/components/delete-account.vue')
  },

]

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      }
    }
    else {
      return { top: 0 }
    }
  },
})

export default router
